export const NO_VALUE = 'NO_VALUE';
export const FALSE_VALUE = 'false';
export const TRUE_VALUE = 'true';

export enum FEATURE_FLAG {
  ADOBE_ANALYTICS_DISABLED = 'ADOBE_ANALYTICS_DISABLED',
  IS_SITE_DOWN_FOR_MAINTENANCE = 'IS_SITE_DOWN_FOR_MAINTENANCE',
  EXAMPLE_FEATURE_FLAG = 'EXAMPLE_FEATURE_FLAG',
  IS_CUSTOM_GRADE_POINTS_ENABLED = 'IS_CUSTOM_GRADE_POINTS_ENABLED',
  IS_ASSIGNMENT_EDITOR_SELECT_STUDENTS_ENABLED = 'IS_ASSIGNMENT_EDITOR_SELECT_STUDENTS_ENABLED',
  IS_RIGHTNOW_INTEGRATION_ENABLED = 'IS_RIGHTNOW_INTEGRATION_ENABLED',
  ENABLE_CBQ_ADVANCE_FILTERING = 'ENABLE_CBQ_ADVANCE_FILTERING',
  ENABLE_CBQ_IMPORT = 'ENABLE_CBQ_IMPORT',
  ENABLE_OSMOSIS_VIDEOS = 'ENABLE_OSMOSIS_VIDEOS'
}

// These are flags I am keeping here that are defined within the scope of the CW app
export enum SHER_EVOL_FEATURE_FLAG {
  ENABLE_BATCH_CONTENT = 'ENABLE_BATCH_CONTENT',
  ENABLE_FEATURE_FLAG_BASED_BATCH_CONTENT = 'ENABLE_FEATURE_FLAG_BASED_BATCH_CONTENT',
  CATALOG_ITEM_IS_PUBLISHED = 'CATALOG_ITEM_IS_PUBLISHED'
}

export const AB_TEST = 'AB_TEST';

export const AB_TEST_CONFIG_MAP: Partial<Record<FEATURE_FLAG, string[]>> = {
  [FEATURE_FLAG.EXAMPLE_FEATURE_FLAG]: [
    'EXAMPLE_FLAVOR_1',
    'EXAMPLE_FLAVOR_2'
  ]
};
