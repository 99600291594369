import {
  get,
  keyBy
} from 'lodash';
import {
  ELSAdobeAnalyticService,
  ELSCommonConfig,
  ELSCommonUIConstants,
  ELSLoggingService
} from '@els/els-ui-common-react';
import {
  fetchAssessmentSubmissions as fetchAssessmentSubmissionsService,
  fetchCatalog as fetchCatalogService,
  fetchCourseCopyPreview as fetchCourseCopyPreviewService,
  fetchMigratedEntitlements,
  fetchNewTokenForCourseSwitcher,
  fetchOsmosisToken,
  fetchSkillPerformance,
  fetchSyllabusItem,
  fetchSyllabusItemsV3,
  fetchUsers as fetchUsersService,
  fetchVitalSource as fetchVitalSourceService,
  postCourseCopy as postCourseCopyService,
  postSyllabusAssignment as postSyllabusAssignmentService,
  postSyllabusItem as postSyllabusItemService,
  putMigrateSyllabusItems,
  putSyllabusAssignment as putSyllabusAssignmentService,
  putSyllabusItems as putSyllabusItemsService
} from '../../apis/sherpath-course-management-service/sherpath-course-management-service.utilities';
import {
  resetMastery,
  fetchIsbns,
  fetchEaqUsers,
  eaqPostAssignment,
  eaqPutAssignment,
  fetchAuthessHealthCheck as fetchAuthessHealthCheckService
} from '../../apis/eaq-app-facade-service/eaq-app-facade-service.utilities';
import {
  fetchAppLinkData as fetchAppLinkDataService,
  fetchHashLink,
  getExternalAppRedirectUrl,
  getLinkNavigation
} from '../../apis/eols-app-link/eols-app-link.utilities';
import {
  fetchCourseSection as fetchCourseSectionService,
  fetchUserCourseSections
} from '../../apis/eols-course-crud/eols-course-crud.utilities';
import { reduxStateDomains } from '../redux.constants';
import {
  AccessTokenRequestDto,
  CatalogWithExternalEntitiesDto,
  CourseCopyPreviewDto,
  CourseManagementSyllabusItemsDto,
  SyllabusAssignmentDto,
  UserDto
} from '../../apis/sherpath-course-management-service/sherpath-course-management-service.dtos';
import {
  CourseISBNDto,
  MasteryResetDto
} from '../../apis/eaq-app-facade-service/eaq-app-facade-service.dtos';

import { AppLinkData } from '../../apis/eols-app-link/eols-app-link.dtos';
import {
  fetchCrosswalkUser as fetchCrosswalkUserService,
  fetchUser as fetchUserService
} from '../../apis/eols-user-management-service/eols-user-management-service.utilities';
import {
  fetchGroupedFeatureFlags,
  fetchGroupFeatureFlag,
  postGroupFeatureFlag
} from '../../apis/eols-features-api/eols-features-api.utilities';
import {
  deleteUserHistoryByUserId,
  fetchUserHistoryByCourseByStateKey as fetchUserHistoryByCourseByStateKeyService,
  postUserHistory as postUserHistoryService
} from '../../apis/eols-user-crud/eols-user-crud.utilities';
import { CourseSectionDto } from '../../apis/eols-course-crud/eols-course-crud.dtos';
import {
  CoursewareUserHistoryStateKey,
  EolsUserHistoryDto,
  EolsUserHistoryResponseDto
} from '../../apis/eols-user-crud/eols-user-crud.dtos';
import { cwSelectors } from './courseware.selectors';
import {
  RecContentItemDto,
  RecContentItemTypeDto
} from '../../apis/rec-gateway/rec-gateway.dtos';
import { fetchPrimaryTaxonomy } from '../../apis/rec-gateway/rec-gateway.utilities';
import {
  PrimaryTaxonomy,
  PrimaryTaxonomyDto
} from '../../apis/rec-gateway/rec-gateway.models';
import { coursewareInitialState } from './courseware.constants';
import {
  AdobeAnalyticsAction,
  AdobeAnalyticsActionProps,
  CourseBuilderStore,
  CoursewareStore,
  NavigateToApp,
  ReduxPageWithCatalog,
  ReduxPageWithPrimaryTaxonomies
} from './courseware.models';
import {
  AssessmentDto,
  AssessmentStatusDto,
  AssessmentSubmissionDto,
  AssignmentDto
} from '../../apis/eols-assessment-service/eols-assessment-service.dtos';
import {
  eolsPostAssignment,
  eolsPutAssignment,
  eolsPutAssignment as eolsPutAssignmentService,
  fetchAssessmentByAssignmentIdAndUserId as fetchAssessmentByAssignmentIdAndUserIdService,
  fetchAssessmentsByAssignmentIdAndUserId,
  fetchAssignment,
  fetchAssignmentResults,
  fetchAssignments as fetchAssignmentsService,
  postCreateAssessmentFromAssignment as postCreateAssessmentFromAssignmentService
} from '../../apis/eols-assessment-service/eols-assessment-service.utilities';
import { SyllabusItemDto } from '../../apis/sherpath-syllabus-service/sherpath-syllabus-service.dtos';
import { ActiveSyllabusItemTypeDto } from '../../apis/sherpath-syllabus-service/sherpath-syllabus-service.constants';
import { SystemType } from '../../apis/eols-user-management-service/eols-user-management-service.dtos';
import {
  fetchEvolveResource,
  fetchLearningObjectLessonAndModule,
  fetchLessonTopics,
  fetchResourceInfo,
  fetchSequence,
  fetchSkillStaticData as fetchSkillStaticDataService
} from '../../apis/ocs-api-service/ocs-api-service.utilities';
import {
  makeGetCatalogInReduxPage,
  makeGetPrimaryTaxonomiesInReduxPage
} from './courseware.selectorHof';
import { removeCookies } from '../../pages/app-link-redirect/app-link-redirect.utilities';
import { scrubProps } from '../../utilities/analytics.utilities';
import { AppLinkOutConfig } from '../../apis/eols-app-link/eols-app-link.models';
import {
  fetchUserEngagementReport as fetchUserEngagementReportService
} from '../../apis/eols-user-engagement/eols-user-engagement.utilities';
import { EolsUserEngagementDto } from '../../apis/eols-user-engagement/eols-user-engagement.dtos';
import { Messages } from '../../translations/message.models';
import {
  SequenceConfigItem,
  SequenceMap
} from '../../apis/ocs-api-service/ocs-api-service.dtos';
import { getModuleSequenceIds } from '../../pages/catalog/catalog.utilities';
import {
  AnalyticsAction,
  AnalyticsActionProps
} from '../../models/analytics.models';
import { getBeforeAfterDueDateDue } from '../../utilities/adobe-analytics-utilities';
import { Application } from '../../apis/eols-app-link/eols-app-link.constants';
import {
  fetchDeepLinkData,
  postDeepLink
} from '../../apis/eols-lms-config-service/eols-lms-config-service.utilities';
import { DeepLinkPostDto } from '../../apis/eols-lms-config-service/eols-lms-config-service.dtos';
import { fetchRelatedHesiCourses } from '../../apis/hesi-app-facade-service/hesi-app-facade-service.utilities';
import {
  getEvolveToken,
  getShadowHealthRelatedCourses,
  goToShadow
} from '../../apis/evolve-user-management-client/evolve-user-management-client.utilities';
import { ShadowHealthCourseDto } from '../../apis/evolve-user-management-client/evolve-user-management-client.dtos';
import { fetchAnalyticsContext } from '../../apis/learning-analytics-service/learning-analytics-service.utilities';
import { LearningAnalyticsRequestDto } from '../../apis/learning-analytics-service/learning-analytics-service.dtos';
import {
  getAssessmentSubmissionsMap,
  shouldGetNewOsmosisToken
} from '../redux.utilities';
import {
  postGetSupportTicketUrl
} from '../../apis/eols-rightnow-integration-api/eols-rightnow-integration-api.utilities';
import { SupportTicketPostBodyDto } from '../../apis/eols-rightnow-integration-api/eols-rightnow-integration-api.dtos';
import {
  getActiveABTestFlags,
  getRandomABTestFlavor
} from '../../utilities/featureFlag.utilities';
import { SyllabusFolderLocationInfo } from '../../pages/catalog/catalog.models';
import { ServerConstants } from '../../components/app/server.constants';
import {
  FeatureFlagDto,
  FeatureFlagsGroupedDto
} from '../../apis/eols-features-api/eols-features-api.dtos';
import { SyllabusItemTypeConfigMap } from '../../constants/content-type.constants';
import { stripNilProps } from '../../utilities/common.utilities';
import { AssignmentManagementAppId } from '../../components/app/app.config';

const fileName = 'courseware.actions';

export interface UserParams {
  userId: string;
  courseId: string;
  roleId: string;
  isbns: string;
}

export const coursewareActionType = {
  DELETE_USER_HISTORY_SUCCESS: `${reduxStateDomains.COURSEWARE_STATE}/DELETE_USER_HISTORY_SUCCESS`,
  FETCH_USER_HISTORY_SUCCESS: `${reduxStateDomains.COURSEWARE_STATE}/FETCH_USER_HISTORY_SUCCESS`,
  FETCH_COURSE_SUCCESS: `${reduxStateDomains.COURSEWARE_STATE}/FETCH_COURSE_SUCCESS`,
  FETCH_GROUPED_FEATURE_FLAGS_SUCCESS: `${reduxStateDomains.COURSEWARE_STATE}/FETCH_GROUPED_FEATURE_FLAGS_SUCCESS`,
  FETCH_FEATURE_ISBNS: `${reduxStateDomains.COURSEWARE_STATE}/FETCH_FEATURE_ISBNS`,
  FETCH_LINK_DATA_SUCCESS: `${reduxStateDomains.COURSEWARE_STATE}/FETCH_LINK_DATA_SUCCESS`,
  FETCH_USER_COURSE_OWNER_RECORDS_SUCCESS: `${reduxStateDomains.COURSEWARE_STATE}/FETCH_USER_COURSE_OWNER_RECORDS_SUCCESS`,
  FETCH_USER_SUCCESS: `${reduxStateDomains.COURSEWARE_STATE}/FETCH_USER_SUCCESS`,
  FETCH_USERS_SUCCESS: `${reduxStateDomains.COURSEWARE_STATE}/FETCH_USERS_SUCCESS`,
  FETCH_EAQ_USERS_SUCCESS: `${reduxStateDomains.COURSEWARE_STATE}/FETCH_EAQ_USERS_SUCCESS`,
  FETCH_EAQ_ISBNS_SUCCESS: `${reduxStateDomains.COURSEWARE_STATE}/FETCH_EAQ_ISBNS_SUCCESS`,
  FETCH_MASTERY_RESET_SUCCESS: `${reduxStateDomains.COURSEWARE_STATE}/FETCH_MASTERY_RESET_SUCCESS`,
  POST_COURSE_SUCCESS: `${reduxStateDomains.COURSEWARE_STATE}/POST_COURSE_SUCCESS`,
  REQUEST_ERROR: `${reduxStateDomains.COURSEWARE_STATE}/REQUEST_ERROR`,
  REQUEST_START: `${reduxStateDomains.COURSEWARE_STATE}/REQUEST_START`,
  REQUEST_SUCCESS: `${reduxStateDomains.COURSEWARE_STATE}/REQUEST_SUCCESS`,
  RESET_STORE: `${reduxStateDomains.COURSEWARE_STATE}/RESET_STORE`,
  RESET_STATE_ON_LAUNCH: `${reduxStateDomains.COURSEWARE_STATE}/RESET_STATE_ON_LAUNCH`,
  RESTORE_STATE: `${reduxStateDomains.COURSEWARE_STATE}/RESTORE_STATE`,
  SET_IS_COURSE_OWNER_SUCCESS: `${reduxStateDomains.COURSEWARE_STATE}/SET_IS_COURSE_OWNER_SUCCESS`,
  SET_ISBNS: `${reduxStateDomains.COURSEWARE_STATE}/SET_ISBNS`,
  SET_MESSAGES: `${reduxStateDomains.COURSEWARE_STATE}/SET_MESSAGES`,
  SET_USER: `${reduxStateDomains.COURSEWARE_STATE}/SET_USER`,
  SET_REGISTERED_TOKEN: `${reduxStateDomains.COURSEWARE_STATE}/SET_REGISTERED_TOKEN`,
  FETCH_CATALOG_SUCCESS: `${reduxStateDomains.COURSEWARE_STATE}/FETCH_CATALOG_SUCCESS`,
  FETCH_PRIMARY_TAXONOMY_SUCCESS: `${reduxStateDomains.COURSEWARE_STATE}/FETCH_PRIMARY_TAXONOMY_SUCCESS`,
  FETCH_ASSIGNMENT_SUCCESS: `${reduxStateDomains.COURSEWARE_STATE}/FETCH_ASSIGNMENT_SUCCESS`,
  DELETE_SYLLABUS_ITEMS_SUCCESS: `${reduxStateDomains.COURSEWARE_STATE}/DELETE_SYLLABUS_ITEMS_SUCCESS`,
  FETCH_ASSESSMENT_SUBMISSIONS_SUCCESS: `${reduxStateDomains.COURSEWARE_STATE}/FETCH_ASSESSMENT_SUBMISSIONS_SUCCESS`,
  FETCH_ALL_ASSESSMENT_SUBMISSIONS_SUCCESS: `${reduxStateDomains.COURSEWARE_STATE}/FETCH_ALL_ASSESSMENT_SUBMISSIONS_SUCCESS`,
  FETCH_ASSIGNMENTS_SUCCESS: `${reduxStateDomains.COURSEWARE_STATE}/FETCH_ASSIGNMENTS_SUCCESS`,
  FETCH_SYLLABUS_ITEMS_SUCCESS: `${reduxStateDomains.COURSEWARE_STATE}/FETCH_SYLLABUS_ITEMS_SUCCESS`,
  POST_SYLLABUS_ASSIGNMENT_SUCCESS: `${reduxStateDomains.COURSEWARE_STATE}/POST_SYLLABUS_ASSIGNMENT_SUCCESS`,
  POST_SYLLABUS_ITEMS_SUCCESS: `${reduxStateDomains.COURSEWARE_STATE}/POST_SYLLABUS_ITEMS_SUCCESS`,
  PUT_SYLLABUS_ASSIGNMENT_SUCCESS: `${reduxStateDomains.COURSEWARE_STATE}/PUT_SYLLABUS_ASSIGNMENT_SUCCESS`,
  PUT_SYLLABUS_ITEMS_BATCH_SUCCESS: `${reduxStateDomains.COURSEWARE_STATE}/PUT_SYLLABUS_ITEMS_BATCH_SUCCESS`,
  PUT_ASSIGNMENTS_SUCCESS: `${reduxStateDomains.COURSEWARE_STATE}/PUT_ASSIGNMENTS_SUCCESS`,
  SET_CHECKED_SYLLABUS_ITEMS: `${reduxStateDomains.COURSEWARE_STATE}/SET_CHECKED_SYLLABUS_ITEMS`,
  SET_SYLLABUS_FOLDER_INFO: `${reduxStateDomains.COURSEWARE_STATE}/SET_SYLLABUS_FOLDER_INFO`,
  SET_IS_BATCH_EDIT_MODE: `${reduxStateDomains.COURSEWARE_STATE}/SET_IS_BATCH_EDIT_MODE`,
  SET_IS_DRAG_DROP_MODE: `${reduxStateDomains.COURSEWARE_STATE}/SET_IS_DRAG_DROP_MODE`,
  UPDATE_COURSE_BUILDER_STATE: `${reduxStateDomains.COURSEWARE_STATE}/UPDATE_COURSE_BUILDER_STATE`,
  FETCH_EOLS_USER_SUCCESS: `${reduxStateDomains.COURSEWARE_STATE}/FETCH_EOLS_USER_SUCCESS`,
  FETCH_CROSSWALK_USERS_SUCCESS: `${reduxStateDomains.COURSEWARE_STATE}/FETCH_CROSSWALK_USERS_SUCCESS`,
  FETCH_EVOLVE_USER_SUCCESS: `${reduxStateDomains.COURSEWARE_STATE}/FETCH_EVOLVE_USER_SUCCESS`,
  POST_USER_HISTORY_SUCCESS: `${reduxStateDomains.COURSEWARE_STATE}/POST_USER_HISTORY_SUCCESS`,
  SET_EBOOK_FILTER: `${reduxStateDomains.COURSEWARE_STATE}/SET_EBOOK_FILTER`,
  HANDLE_EBOOK_FILTER_CHANGE: `${reduxStateDomains.COURSEWARE_STATE}/HANDLE_EBOOK_FILTER_CHANGE`,
  HANDLE_PRODUCT_FILTER_CHANGE: `${reduxStateDomains.COURSEWARE_STATE}/HANDLE_PRODUCT_FILTER_CHANGE`,
  HANDLE_RESOURCE_STATUS_FILTER_CHANGE: `${reduxStateDomains.COURSEWARE_STATE}/HANDLE_RESOURCE_STATUS_FILTER_CHANGE`,
  HANDLE_RESOURCE_TYPE_FILTER_CHANGE: `${reduxStateDomains.COURSEWARE_STATE}/HANDLE_RESOURCE_TYPE_FILTER_CHANGE`,
  HANDLE_RESOURCE_GRADING_FILTER_CHANGE: `${reduxStateDomains.COURSEWARE_STATE}/HANDLE_RESOURCE_GRADING_FILTER_CHANGE`,
  SET_ASSESSMENT_START_TIME_MAP: `${reduxStateDomains.COURSEWARE_STATE}/SET_ASSESSMENT_START_TIME_MAP`,
  SET_APP_LINK_COOKIES: `${reduxStateDomains.COURSEWARE_STATE}/SET_APP_LINK_COOKIES`,
  UPDATE_COLLAPSED_FOLDER_IDS: `${reduxStateDomains.COURSEWARE_STATE}/UPDATE_COLLAPSED_FOLDER_IDS`,
  FETCH_SKILL_STATIC_DATA: `${reduxStateDomains.COURSEWARE_STATE}/FETCH_SKILL_STATIC_DATA`,
  FETCH_SKILL_SUBMISSION_RECORD: `${reduxStateDomains.COURSEWARE_STATE}/FETCH_SKILL_SUBMISSION_RECORD`,
  SET_STORE_PROPS: `${reduxStateDomains.COURSEWARE_STATE}/SET_STORE_PROPS`,
  FETCH_COURSE_COPY_PREVIEW_SUCCESS: `${reduxStateDomains.COURSEWARE_STATE}/FETCH_COURSE_COPY_PREVIEW_SUCCESS`,
  SET_HESI_FOCUS_CHAPTER_FILTER: `${reduxStateDomains.COURSEWARE_STATE}/SET_HESI_FOCUS_CHAPTER_FILTER`,
  SET_BATCH_EDIT_SELECTED_SYLLABUS_ITEMS: `${reduxStateDomains.COURSEWARE_STATE}/SET_BATCH_EDIT_SELECTED_SYLLABUS_ITEMS`,
  SET_BATCH_EDIT_UPDATED_SYLLABUS_ITEMS: `${reduxStateDomains.COURSEWARE_STATE}/SET_BATCH_EDIT_UPDATED_SYLLABUS_ITEMS`,
  FETCH_USER_ENGAGEMENT_REPORT_SUCCESS: `${reduxStateDomains.COURSEWARE_STATE}/FETCH_USER_ENGAGEMENT_REPORT_SUCCESS`,
  CLEAR_ALL_FILTERS: `${reduxStateDomains.COURSEWARE_STATE}/CLEAR_ALL_FILTERS`,
  FETCH_MODULE_SEQUENCE_SUCCESS: `${reduxStateDomains.COURSEWARE_STATE}/FETCH_MODULE_SEQUENCE_SUCCESS`,
  POST_CREATE_ASSESSMENT_FROM_ASSIGNMENT_SUCCESS: `${reduxStateDomains.COURSEWARE_STATE}/POST_CREATE_ASSESSMENT_FROM_ASSIGNMENT_SUCCESS`,
  FETCH_USER_RECENT_ASSESSMENT_BY_ASSIGNMENT_ID: `${reduxStateDomains.COURSEWARE_STATE}/FETCH_USER_RECENT_ASSESSMENT_BY_ASSIGNMENT_ID`,
  FETCH_GROUP_ACTIVITY_SUCCESS: `${reduxStateDomains.COURSEWARE_STATE}/FETCH_GROUP_ACTIVITY_SUCCESS`,
  SET_ENABLE_DEEP_LINK: `${reduxStateDomains.COURSEWARE_STATE}/SET_ENABLE_DEEP_LINK`,
  SET_HAS_RUN_AUTHESS_HEALTH_CHECK: `${reduxStateDomains.COURSEWARE_STATE}/SET_HAS_RUN_AUTHESS_HEALTH_CHECK`,
  FETCH_OSMOSIS_TOKEN_SUCCESS: `${reduxStateDomains.COURSEWARE_STATE}/FETCH_OSMOSIS_TOKEN_SUCCESS`,
  FETCH_MIGRATED_ENTITLEMENTS_SUCCESS: `${reduxStateDomains.COURSEWARE_STATE}/FETCH_MIGRATED_ENTITLEMENTS_SUCCESS`,
  FETCH_AB_TEST_FLAVOR_SUCCESS: `${reduxStateDomains.COURSEWARE_STATE}/FETCH_AB_TEST_FLAVOR_SUCCESS`,
  SAVE_ASSIGNMENT: `${reduxStateDomains.COURSEWARE_STATE}/SAVE_ASSIGNMENT`,
  SET_INTERACTIVE_REVIEW_CATALOG_ITEM: `${reduxStateDomains.COURSEWARE_STATE}/SET_INTERACTIVE_REVIEW_CATALOG_ITEM`,
  SET_IMPORT_SELECTION: `${reduxStateDomains.COURSEWARE_STATE}/SET_IMPORT_SELECTION`
};

const restoreState = (state) => ({
  type: coursewareActionType.RESTORE_STATE,
  payload: state
});

export const resetState = () => (dispatch) => {
  dispatch({
    type: coursewareActionType.RESET_STORE,
    payload: null
  });
};

export const resetStateOnLaunch = () => (dispatch) => {
  dispatch({
    type: coursewareActionType.RESET_STATE_ON_LAUNCH,
    payload: null
  });
};

const trackAction = (props: AnalyticsActionProps) => (dispatch, getState) => {
  const defaultActionProps = cwSelectors.getDefaultActionProps(getState());
  const actionProps = scrubProps(props.props);
  const _props = {
    ...defaultActionProps,
    ...actionProps
  };
  ELSLoggingService.info(fileName, `New Relic PageAction: ${props.action}, ${JSON.stringify(_props)}`);
  if (!window.newrelic) {
    return;
  }
  window.newrelic.addPageAction(props.action, _props);
};

const trackAdobeAction = (props: AdobeAnalyticsActionProps) => (dispatch, getState) => {
  const defaultPageData = cwSelectors.getDefaultAdobeAnalyticsPageData(getState());
  const educationProps = stripNilProps(props.pageData.education);
  const pageData = {
    ...defaultPageData,
    education: {
      ...defaultPageData.education,
      ...educationProps
    }
  };
  ELSAdobeAnalyticService.trackEvent(props.action, pageData);
};

const handleRequestError = (dispatch, getState) => (
  error: Error,
  action: string,
  isTrackRequestCount = true
) => {
  const url = get(error, 'config.url', '');
  const method = get(error, 'config.method', '');
  const status = get(error, 'response.status', '');
  if (status === 400) {
    // Likely 400 error here due to overloaded headers
    removeCookies();
  }

  trackAction({
    action: AnalyticsAction.API_ERROR,
    props: {
      status,
      action,
      url,
      method
    }
  })(dispatch, getState);

  dispatch({
    type: coursewareActionType.REQUEST_ERROR,
    payload: {
      error,
      isTrackRequestCount
    }
  });
  return Promise.reject(error);
};

const fetchCourseSection = (
  courseSectionId: string,
  useCache = false
) => (dispatch, getState): Promise<CourseSectionDto> => {
  if (useCache) {
    const courseSection = cwSelectors.getCourse(getState());
    if (courseSection) {
      return Promise.resolve(courseSection);
    }
  }
  dispatch({ type: coursewareActionType.REQUEST_START });
  return fetchCourseSectionService(courseSectionId)
    .then((response) => {
      dispatch({
        type: coursewareActionType.FETCH_COURSE_SUCCESS,
        payload: response
      });
      return response;
    })
    .catch((e) => handleRequestError(dispatch, getState)(e, 'fetchCourseSection'));
};

const fetchAssessmentsByAssignmentIdAndUserIdAction = (
  userId: string,
  assignmentId: number
) => (dispatch, getState): Promise<AssessmentDto[]> => {
  dispatch({ type: coursewareActionType.REQUEST_START });
  return fetchAssessmentsByAssignmentIdAndUserId(userId, assignmentId)
    .then((response) => {
      dispatch({ type: coursewareActionType.REQUEST_SUCCESS });
      return response;
    })
    .catch((e) => handleRequestError(dispatch, getState)(e, 'fetchAssessmentsByAssignmentIdAndUserIdAction'));
};

const fetchRecentAssessmentOfAssignment = (
  userId: string,
  assignmentId: number
) => (dispatch, getState): Promise<AssessmentDto> => {
  dispatch({ type: coursewareActionType.REQUEST_START });
  return fetchAssessmentByAssignmentIdAndUserIdService(userId, assignmentId)
    .then((response) => {
      dispatch({
        type: coursewareActionType.FETCH_USER_RECENT_ASSESSMENT_BY_ASSIGNMENT_ID,
        payload: response
      });
      return response;
    })
    .catch((e) => handleRequestError(dispatch, getState)(e, 'fetchRecentAssessmentOfAssignment'));
};

const fetchEaqUsersAction = (courseSectionIds: string[], roleId: string, userId: string, useCache = false) => (dispatch, getState): Promise<UserDto[]> => {

  if (useCache) {
    const users = cwSelectors.getEaqUsers(getState());
    if (users && users.length > 0) {
      return Promise.resolve(users);
    }
  }

  dispatch({ type: coursewareActionType.REQUEST_START });

  return fetchEaqUsers(courseSectionIds)
    .then((users: UserDto[]) => {
      dispatch({
        type: coursewareActionType.FETCH_EAQ_USERS_SUCCESS,
        payload: users
      });
      return users;
    })
    .catch((e) => handleRequestError(dispatch, getState)(e, 'fetchEaqUsers'));

};

const fetchIsbnsAction = (courseSectionId: string, userId: number) => (dispatch, getState) => {
  dispatch({ type: coursewareActionType.REQUEST_START });

  return fetchIsbns(courseSectionId, userId)
    .then((isbns: CourseISBNDto[]) => {
      dispatch({
        type: coursewareActionType.FETCH_EAQ_ISBNS_SUCCESS,
        payload: isbns
      });
      return isbns;
    })
    .catch((e) => handleRequestError(dispatch, getState)(e, 'fetchIsbnsAction'));

};

const resetMasteryAction = (isbn: string, userId: number) => (dispatch, getState) => {
  dispatch({ type: coursewareActionType.REQUEST_START });

  return resetMastery(isbn, userId)
    .then((value: MasteryResetDto) => {
      dispatch({
        type: coursewareActionType.FETCH_MASTERY_RESET_SUCCESS,
        payload: value
      });

    })
    .catch((e) => handleRequestError(dispatch, getState)(e, 'resetMasteryAction '));

};

const fetchUsers = (courseSectionIds: string[], roleId: string, userId: string, useCache = false) => (dispatch, getState): Promise<UserDto[]> => {

  if (useCache) {
    const users = cwSelectors.getUsers(getState());
    if (users && users.length > 0) {
      return Promise.resolve(users);
    }
  }

  dispatch({ type: coursewareActionType.REQUEST_START });
  if (roleId === ELSCommonUIConstants.userType.Instructor) {
    return fetchUsersService(courseSectionIds)
      .then((users: UserDto[]) => {
        dispatch({
          type: coursewareActionType.FETCH_USERS_SUCCESS,
          payload: users
        });
        return users;
      })
      .catch((e) => handleRequestError(dispatch, getState)(e, 'fetchUsers'));
  }

  return fetchUserService(userId)
    .then((user: UserDto) => {
      dispatch({
        type: coursewareActionType.FETCH_USERS_SUCCESS,
        payload: [user]
      });
      return [user];
    })
    .catch((e) => handleRequestError(dispatch, getState)(e, 'fetchUsers'));

};

// In the future we should consider retrieving this value from the token always
// For pilot and for development we need to separate these values from the token
// If we can reliably get pilot isbns into the token through evolve then this value should never be duplicated here
// Also note this is called Isbns plural because we can support mashup courses with multiple isbns
const setIsbns = isbn => dispatch => dispatch({
  type: coursewareActionType.SET_ISBNS,
  payload: isbn
});

const setUser = ({
  courseId,
  roleId,
  userId
}: UserParams) => dispatch => dispatch({
  type: coursewareActionType.SET_USER,
  payload: {
    courseSectionId: courseId,
    roleId,
    userId
  }
});

const setMessages = (messages: Messages) => dispatch => dispatch({
  type: coursewareActionType.SET_MESSAGES,
  payload: messages
});

const fetchAppLinkData = (
  linkId: string
) => (dispatch, getState) => {
  dispatch({ type: coursewareActionType.REQUEST_START });
  return fetchAppLinkDataService(linkId)
    .then((response: AppLinkData) => {
      dispatch({
        payload: response,
        type: coursewareActionType.FETCH_LINK_DATA_SUCCESS
      });
      return response;
    })
    .catch((e) => handleRequestError(dispatch, getState)(e, 'fetchAppLinkData'));
};

const getExternalAppRedirectUrlAction = (
  config: AppLinkOutConfig,
  token: string = null
) => () => {
  return getExternalAppRedirectUrl(config, token);
};

const navigateToApp: NavigateToApp = (
  config: AppLinkOutConfig,
  isNewTab = false,
  token: string = null
) => (dispatch, getState) => {
  dispatch({ type: coursewareActionType.REQUEST_START });
  return getExternalAppRedirectUrl(config, token)
    .then(({ redirectUrl }) => {

      trackAction({
        action: AnalyticsAction.APP_LINK_OUT,
        props: {
          linkType: config.action,
          srcApp: config.altSrcApp || Application.ASSIGNMENT_MANAGEMENT,
          targetApp: config.app
        }
      })(dispatch, getState);

      if (isNewTab) {
        window.open(redirectUrl, '_blank');
        dispatch({ type: coursewareActionType.REQUEST_SUCCESS });
      } else {
        window.location.href = redirectUrl;
      }
    })
    .catch((e) => handleRequestError(dispatch, getState)(e, 'navigateToApp'));
};

const returnAppLink = (
  props: {
    linkId: string;
    assignmentIds: number[];
  }
) => (dispatch, getState) => {
  dispatch({ type: coursewareActionType.REQUEST_START });
  return getLinkNavigation(props)
    .then(({ redirectUrl }) => {
      dispatch({ type: coursewareActionType.REQUEST_SUCCESS });
      window.location.href = redirectUrl;
    })
    .catch((e) => handleRequestError(dispatch, getState)(e, 'returnAppLink'));
};

const fetchUserCourseOwnerRecords = (
  eolsUserHistoryRequest: {
    userId: number;
    courseSectionId: number;
    instanceId?: string;
  }
) => (dispatch, getState): Promise<EolsUserHistoryResponseDto[]> => {
  dispatch({ type: coursewareActionType.REQUEST_START });
  return fetchUserHistoryByCourseByStateKeyService({
    ...eolsUserHistoryRequest,
    stateKey: CoursewareUserHistoryStateKey.IS_COURSE_OWNER
  })
    .then((response) => {
      dispatch({
        type: coursewareActionType.FETCH_USER_COURSE_OWNER_RECORDS_SUCCESS,
        payload: response
      });
      return response;
    })
    .catch((error) => {
      if (error.response && error.response.status === 404) {
        dispatch({
          type: coursewareActionType.FETCH_USER_COURSE_OWNER_RECORDS_SUCCESS,
          payload: []
        });
        return [];
      }
      return Promise.reject(error);
    })
    .catch((error) => {
      return handleRequestError(dispatch, getState)(error, 'fetchUserCourseOwnerRecords');
    });
};

const fetchUserHistoryByCourseByStateKey = (
  eolsUserHistoryRequest: {
    userId: number;
    stateKey: CoursewareUserHistoryStateKey;
    courseSectionId: number;
    instanceId?: string;
  }
) => (dispatch, getState): Promise<EolsUserHistoryResponseDto[]> => {
  dispatch({ type: coursewareActionType.REQUEST_START });
  return fetchUserHistoryByCourseByStateKeyService(eolsUserHistoryRequest)
    .then((response) => {
      dispatch({
        type: coursewareActionType.FETCH_USER_HISTORY_SUCCESS,
        payload: {
          response,
          stateKey: eolsUserHistoryRequest.stateKey
        }
      });
      return response;
    })
    .catch((error) => {
      if (error.response && error.response.status === 404) {
        dispatch({
          type: coursewareActionType.FETCH_USER_HISTORY_SUCCESS,
          payload: {
            response: [],
            stateKey: eolsUserHistoryRequest.stateKey
          }
        });
        return [];
      }
      return handleRequestError(dispatch, getState)(error, 'fetchUserHistoryByCourseByStateKey');
    });
};

const deleteUserHistoryWithUserId = (
  eolsUserHistoryRequest: {
    userId: number;
    instanceId?: string;
  }
) => (dispatch, getState): Promise<EolsUserHistoryResponseDto[]> => {
  dispatch({ type: coursewareActionType.REQUEST_START });
  return deleteUserHistoryByUserId(eolsUserHistoryRequest)
    .then((response) => {
      dispatch({
        type: coursewareActionType.DELETE_USER_HISTORY_SUCCESS,
        payload: { response }
      });
      return response;
    })
    .catch((error) => {
      if (error.response && error.response.status === 404) {
        dispatch({
          type: coursewareActionType.DELETE_USER_HISTORY_SUCCESS,
          payload: { response: [] }
        });
        return [];
      }
      return handleRequestError(dispatch, getState)(error, 'deleteUserHistoryByUserId');
    });
};

const fetchSkillSubmissionRecords = (
  skillSubmissionDataRequest: {
    userId: number;
    assessmentId: string;
    courseSectionId: number;
  }
) => (dispatch, getState): Promise<EolsUserHistoryResponseDto[]> => {
  dispatch({ type: coursewareActionType.REQUEST_START });
  return fetchUserHistoryByCourseByStateKeyService({
    userId: skillSubmissionDataRequest.userId,
    stateKey: `ASSESSMENT_${skillSubmissionDataRequest.assessmentId}`,
    courseSectionId: skillSubmissionDataRequest.courseSectionId
  })
    .then((response) => {
      dispatch({
        type: coursewareActionType.FETCH_SKILL_SUBMISSION_RECORD,
        payload: response
      });
      return response;
    })
    .catch((error) => {
      if (error.response && error.response.status === 404) {
        dispatch({
          type: coursewareActionType.FETCH_SKILL_SUBMISSION_RECORD,
          payload: []
        });
        return [];
      }
      return Promise.reject(error);
    })
    .catch((error) => {
      return handleRequestError(dispatch, getState)(error, 'fetchSkillSubmissionRecords');
    });
};

const setIsCourseOwner = (
  eolsUserId: number,
  courseSectionId: string,
  isCourseOwner: string,
  eolsUserHistoryDto: Partial<EolsUserHistoryResponseDto> = {}
) => (dispatch, getState): Promise<EolsUserHistoryResponseDto> => {
  dispatch({ type: coursewareActionType.REQUEST_START });
  return postUserHistoryService({
    ...eolsUserHistoryDto,
    stateInfo: isCourseOwner,
    stateKey: CoursewareUserHistoryStateKey.IS_COURSE_OWNER,
    eolsUser: {
      id: eolsUserId
    },
    courseSectionId: parseInt(courseSectionId, 10)
  })
    .then((response) => {
      dispatch({
        type: coursewareActionType.SET_IS_COURSE_OWNER_SUCCESS,
        payload: response
      });
      return response;
    })
    .catch((e) => handleRequestError(dispatch, getState)(e, 'setIsCourseOwner'));
};

const postUserHistory = (
  eolsUserHistoryDto: EolsUserHistoryDto
) => (dispatch, getState): Promise<EolsUserHistoryResponseDto> => {
  dispatch({ type: coursewareActionType.REQUEST_START });
  return postUserHistoryService(eolsUserHistoryDto)
    .then((response) => {
      dispatch({
        type: coursewareActionType.POST_USER_HISTORY_SUCCESS,
        payload: response
      });
      return response;
    })
    .catch((e) => handleRequestError(dispatch, getState)(e, 'postUserHistory'));
};

const postGroupFeatureFlagAction = (eolsApp: string, featureName: string, group: string, featureValue: string): Promise<FeatureFlagDto> => {
  return postGroupFeatureFlag(eolsApp, featureName, group, featureValue)
    .then(() => {
      return {
        createdAt: null,
        eolsApp,
        featureName,
        featureValue,
        group,
        updatedAt: null
      };
    });
};

const fetchABFlavor = (abTestFlag: FeatureFlagsGroupedDto) => (dispatch, getState) => {
  const abTestFlavors = cwSelectors.getABTestFlavors(getState());
  const abTestFlavor = abTestFlavors.find((flag) => {
    return flag.featureName === abTestFlag.featureName;
  });
  if (abTestFlavor) {
    return Promise.resolve(abTestFlavor);
  }
  const courseSectionId = cwSelectors.getCourseSectionId(getState());
  dispatch({ type: coursewareActionType.REQUEST_START });
  const app = `${AssignmentManagementAppId}_${abTestFlag.featureName}`;
  const key = abTestFlag.featureName;
  return fetchGroupFeatureFlag(app, key, courseSectionId)
    .catch((error) => {
      if (error.response && error.response.status === 404) {
        const newFlavor = getRandomABTestFlavor(abTestFlag);
        if (!newFlavor) {
          ELSLoggingService.error(fileName, `AB test config missing for flag: ${key}`);
          return Promise.resolve({
            createdAt: null,
            eolsApp: app,
            featureName: key,
            featureValue: null,
            group: courseSectionId,
            updatedAt: null
          });
        }
        return postGroupFeatureFlagAction(app, key, courseSectionId, newFlavor);
      }
      return Promise.reject(error);
    })
    .then((response) => {
      dispatch({
        payload: response,
        type: coursewareActionType.FETCH_AB_TEST_FLAVOR_SUCCESS
      });
      return response;
    })
    .catch((e) => handleRequestError(dispatch, getState)(e, 'fetchABFlavor'));
};

const fetchAllAppFeatureFlags = () => (dispatch, getState) => {
  const featureFlagsGrouped = cwSelectors.getFeatureFlagsGrouped(getState());
  if (featureFlagsGrouped) {
    return Promise.resolve(featureFlagsGrouped);
  }
  dispatch({ type: coursewareActionType.REQUEST_START });
  return fetchGroupedFeatureFlags()
    .then((response) => {
      dispatch({
        payload: response,
        type: coursewareActionType.FETCH_GROUPED_FEATURE_FLAGS_SUCCESS
      });

      const courseSectionId = cwSelectors.getCourseSectionId(getState());

      const promises = [];
      const activeABTests = getActiveABTestFlags(response, courseSectionId);

      if (activeABTests && activeABTests.length) {
        activeABTests.forEach((activeABTest) => {
          promises.push(fetchABFlavor(activeABTest)(dispatch, getState));
        });
      }

      if (promises.length) {
        return Promise.all(promises)
          .then(() => {
            return response;
          });
      }

      return response;
    })
    .catch((e) => handleRequestError(dispatch, getState)(e, 'fetchAllAppFeatureFlags'));
};

const fetchModuleSequences = (catalog: CatalogWithExternalEntitiesDto) => (dispatch, getState): Promise<SequenceMap> => {

  if (!catalog) {
    return Promise.reject();
  }

  const moduleSequenceIds = getModuleSequenceIds({
    catalog
  });

  if (!moduleSequenceIds || !moduleSequenceIds.length) {
    return Promise.resolve({});
  }

  dispatch({ type: coursewareActionType.REQUEST_START });

  return Promise.all(
    moduleSequenceIds.map((item) => {
      return fetchSequence(item.moduleSequenceId)
        .then((sequenceDto): SequenceConfigItem => {
          return {
            sequenceDto,
            taxon: item.taxon
          };
        })
        .catch((error) => {
          if (error.response && error.response.status === 404) {
            return null;
          }
          return Promise.reject(error);
        })
        .catch((e) => handleRequestError(dispatch, getState)(e, 'fetchSequence', false))
        .catch(() => null);
    })
  )
    .then((response: SequenceConfigItem[]) => {
      const map: SequenceMap = response.reduce((acc, cur: SequenceConfigItem) => {
        if (!cur || !cur.sequenceDto) {
          return acc;
        }
        return {
          ...acc,
          [cur.sequenceDto.resourceVtwId]: cur
        };
      }, {} as SequenceMap);
      dispatch({
        payload: map,
        type: coursewareActionType.FETCH_MODULE_SEQUENCE_SUCCESS
      });
      return map;
    });
};

const fetchCatalog = (
  isbns: string[],
  learningTypes: RecContentItemTypeDto[],
  useCache = false,
  reduxPage?: ReduxPageWithCatalog
) => (dispatch, getState): Promise<CatalogWithExternalEntitiesDto> => {
  if (useCache) {
    const catalogFromStore = cwSelectors.getCatalog(getState());
    if (reduxPage) {
      const pageCatalog = makeGetCatalogInReduxPage(reduxPage)(getState());
      if (pageCatalog !== coursewareInitialState[reduxPage].catalog) {
        return Promise.resolve(pageCatalog);
      }
    } else if (catalogFromStore !== coursewareInitialState.catalog) {
      return Promise.resolve(catalogFromStore);
    }
  }

  dispatch({ type: coursewareActionType.REQUEST_START });
  return fetchCatalogService(isbns, learningTypes)
    .then((data) => {
      dispatch({
        payload: {
          data,
          reduxPage
        },
        type: coursewareActionType.FETCH_CATALOG_SUCCESS
      });
      fetchModuleSequences(data)(dispatch, getState);
      return data;
    })
    .catch((e) => handleRequestError(dispatch, getState)(e, 'fetchCatalog'));
};

const fetchPrimaryTaxonomies = (
  primaryTaxonomies: PrimaryTaxonomy[],
  useCache = false,
  reduxPage?: ReduxPageWithPrimaryTaxonomies
  // eslint-disable-next-line sonarjs/cognitive-complexity
) => (dispatch, getState): Promise<PrimaryTaxonomy[]> => {
  const primaryTaxonomiesFromStore = cwSelectors.getPrimaryTaxonomies(getState());
  const isDataExisted = primaryTaxonomiesFromStore !== coursewareInitialState.primaryTaxonomies;
  if (useCache && isDataExisted) {
    if (reduxPage) {
      const primaryTaxonomiesFromPageStore = makeGetPrimaryTaxonomiesInReduxPage(reduxPage)(getState());
      if (primaryTaxonomiesFromPageStore !== coursewareInitialState[reduxPage].primaryTaxonomies) {
        return Promise.resolve(primaryTaxonomiesFromPageStore);
      }
    } else if (primaryTaxonomiesFromStore !== coursewareInitialState.primaryTaxonomies) {
      return Promise.resolve(primaryTaxonomiesFromStore);
    }
  }
  dispatch({ type: coursewareActionType.REQUEST_START });

  return Promise.all(
    primaryTaxonomies.map((primaryTaxonomy) => {
      return fetchPrimaryTaxonomy(primaryTaxonomy.isbn)
        .then((response: PrimaryTaxonomyDto) => {
          if (response.data.length !== 1) {
            ELSLoggingService.error(fileName, 'Primary taxonomy DTO should ALWAYS have 1 primary taxonomy');
          }
          return {
            ...primaryTaxonomy,
            taxonomy: response
          };
        })
        .catch((error) => {
          if (error.response && error.response.status === 404) {
            return {
              ...primaryTaxonomy,
              taxonomy: null
            };
          }
          return Promise.reject(error);
        });
    })
  )
    .then((responses) => {
      return responses.reduce((acc, cur) => {
        if (!cur.taxonomy) {
          return acc;
        }
        return [...acc, cur];
      }, []);
    })
    .then((data) => {
      dispatch({
        payload: {
          data,
          reduxPage
        },
        type: coursewareActionType.FETCH_PRIMARY_TAXONOMY_SUCCESS
      });
      return data;
    })
    .catch((e) => handleRequestError(dispatch, getState)(e, 'fetchPrimaryTaxonomies'));
};

const openVitalSource = (
  isbn: string,
  page: number
) => (dispatch, getState) => {
  dispatch({ type: coursewareActionType.REQUEST_START });
  return fetchVitalSourceService({
    isbn,
    page
  })
    .then(vitalSource => {
      dispatch({ type: coursewareActionType.REQUEST_SUCCESS });
      return window.open(vitalSource.signInUrl, '_blank');
    })
    .catch((e) => handleRequestError(dispatch, getState)(e, 'openVitalSource'));
};

const getInProgressAssessmentId = (assessmentId: number, assignmentId: number, userId: string) => (dispatch, getState): Promise<number> => {
  return assessmentId
    ? Promise.resolve(assessmentId)
    : fetchRecentAssessmentOfAssignment(userId, assignmentId)(dispatch, getState)
      .then(recentAssessment => {
        return recentAssessment.status === AssessmentStatusDto.IN_PROGRESS ? recentAssessment.id : null;
      });
};

const fetchAssessmentSubmissions = (
  assignmentId: string,
  isTrackRequestCount = true
) => (dispatch, getState): Promise<AssessmentSubmissionDto[]> => {
  if (isTrackRequestCount) {
    dispatch({ type: coursewareActionType.REQUEST_START });
  }
  return fetchAssessmentSubmissionsService(assignmentId)
    .then((assessmentSubmissions) => {
      const payload = {
        assignmentId,
        assessmentSubmissions,
        isTrackRequestCount
      };
      dispatch({
        payload,
        type: coursewareActionType.FETCH_ASSESSMENT_SUBMISSIONS_SUCCESS
      });
      return assessmentSubmissions;
    })
    .catch((e) => handleRequestError(dispatch, getState)(e, 'fetchAssessmentSubmissions', isTrackRequestCount));
};

const fetchAllAssessmentSubmissionsAction = (
  assignmentIds: string[],
  isTrackRequestCount = true
) => (dispatch, getState): Promise<Record<string, AssessmentSubmissionDto[]>> => {
  if (isTrackRequestCount) {
    dispatch({ type: coursewareActionType.REQUEST_START });
  }
  return Promise.all(
    assignmentIds.map((assignmentId) => {
      return fetchAssessmentSubmissionsService(assignmentId)
        .then((assessmentSubmissions) => {
          return {
            assignmentId,
            assessmentSubmissions
          };
        });
    })
  )
    .then((assessmentSubmissionsResponses) => {
      const payload = {
        assessmentSubmissionsMap: getAssessmentSubmissionsMap(assessmentSubmissionsResponses),
        isTrackRequestCount
      };
      dispatch({
        payload,
        type: coursewareActionType.FETCH_ALL_ASSESSMENT_SUBMISSIONS_SUCCESS
      });
      return payload.assessmentSubmissionsMap;
    })
    .catch((e) => {
      return handleRequestError(dispatch, getState)(e, 'fetchAllAssessmentSubmissionsAction', isTrackRequestCount);
    });
};

const fetchAssignments = (
  courseSectionId: string
) => (dispatch, getState): Promise<AssignmentDto[]> => {
  dispatch({ type: coursewareActionType.REQUEST_START });
  return fetchAssignmentsService(courseSectionId)
    .then((assignments) => {
      const payload = { assignments };
      dispatch({
        payload,
        type: coursewareActionType.FETCH_ASSIGNMENTS_SUCCESS
      });
      return Promise.resolve(assignments);
    })
    .catch((e) => handleRequestError(dispatch, getState)(e, 'fetchAssignments'));
};

const fetchSyllabusItems = (
  courseSectionId: string,
  useCache = true
) => (dispatch, getState): Promise<CourseManagementSyllabusItemsDto> => {
  const isDataExisted = cwSelectors.getAllSyllabusItems(getState()) !== coursewareInitialState.syllabusItems
    && cwSelectors.getAssignments(getState()) !== coursewareInitialState.assignments;
  if (useCache && isDataExisted) {
    return Promise.resolve({
      externalEntities: cwSelectors.getExternalEntities(getState()),
      syllabusItems: cwSelectors.getAllSyllabusItems(getState())
    });
  }

  dispatch({ type: coursewareActionType.REQUEST_START });

  return fetchSyllabusItemsV3({
    courseSectionIds: [courseSectionId]
  })
    .then((data: CourseManagementSyllabusItemsDto) => {
      const payload = data;
      dispatch({
        payload,
        type: coursewareActionType.FETCH_SYLLABUS_ITEMS_SUCCESS
      });
      return Promise.resolve(payload);
    })
    .catch((e) => handleRequestError(dispatch, getState)(e, 'fetchSyllabusItems'));
};

const postSyllabusItem = (syllabusItem) => (dispatch, getState): Promise<SyllabusItemDto> => {
  dispatch({ type: coursewareActionType.REQUEST_START });
  return postSyllabusItemService(syllabusItem)
    .then((data: SyllabusItemDto) => {
      dispatch({
        payload: data,
        type: coursewareActionType.POST_SYLLABUS_ITEMS_SUCCESS
      });
      return data;
    })
    .catch((e) => handleRequestError(dispatch, getState)(e, 'postSyllabusItem'));
};

const putSyllabusItems = (newSyllabusItems: SyllabusItemDto[]) => (dispatch, getState): Promise<SyllabusItemDto[]> => {
  dispatch({ type: coursewareActionType.REQUEST_START });
  return putSyllabusItemsService(newSyllabusItems)
    .then((data: SyllabusItemDto[]) => {
      dispatch({
        payload: data,
        type: coursewareActionType.PUT_SYLLABUS_ITEMS_BATCH_SUCCESS
      });
      return data;
    })
    .catch((e) => handleRequestError(dispatch, getState)(e, 'putSyllabusItems'));
};

const postSyllabusAssignment = (syllabusAssignmentDto: SyllabusAssignmentDto) => (dispatch, getState): Promise<SyllabusAssignmentDto> => {
  dispatch({ type: coursewareActionType.REQUEST_START });
  return postSyllabusAssignmentService(syllabusAssignmentDto)
    .then((data: SyllabusAssignmentDto) => {
      // Only considering assignment with due dates as assignments for Adobe
      if (data.assignment && data.assignment.dueDate) {
        trackAdobeAction({
          action: AdobeAnalyticsAction.ASSIGNMENT_CREATED,
          pageData: {
            education: {
              assignmentType: data.assignment.assignmentType,
              assignmentId: data.assignment.id.toString(),
              assignmentName: data.assignment.title,
              beforeAfterDueDate: getBeforeAfterDueDateDue(data.assignment.dueDate)
            }
          }
        })(dispatch, getState);
      }
      dispatch({
        payload: data,
        type: coursewareActionType.POST_SYLLABUS_ASSIGNMENT_SUCCESS
      });
      return data;
    })
    .catch((e) => handleRequestError(dispatch, getState)(e, 'postSyllabusAssignment'));
};

const putSyllabusAssignment = (syllabusAssignmentDto: SyllabusAssignmentDto) => (dispatch, getState): Promise<SyllabusAssignmentDto> => {
  dispatch({ type: coursewareActionType.REQUEST_START });
  return putSyllabusAssignmentService(syllabusAssignmentDto)
    .then((data: SyllabusAssignmentDto) => {
      // Only considering assignment with due dates as assignments for Adobe
      if (data.assignment && data.assignment.dueDate) {
        trackAdobeAction({
          action: AdobeAnalyticsAction.ASSIGNMENT_CREATED,
          pageData: {
            education: {
              assignmentType: data.assignment.assignmentType,
              assignmentId: data.assignment.id.toString(),
              assignmentName: data.assignment.title,
              beforeAfterDueDate: getBeforeAfterDueDateDue(data.assignment.dueDate)
            }
          }
        })(dispatch, getState);
      }
      dispatch({
        payload: data,
        type: coursewareActionType.PUT_SYLLABUS_ASSIGNMENT_SUCCESS
      });
      return data;
    })
    .catch((e) => handleRequestError(dispatch, getState)(e, 'putSyllabusAssignment'));
};

const deleteSyllabusItems = (syllabusItemsToDelete: SyllabusItemDto[]) => (dispatch, getState) => {
  dispatch({ type: coursewareActionType.REQUEST_START });
  return putSyllabusItemsService(syllabusItemsToDelete.map((item) => {
    return {
      ...item,
      isDeleted: true
    };
  }))
    .then((data: SyllabusItemDto[]) => {
      dispatch({
        payload: data,
        type: coursewareActionType.DELETE_SYLLABUS_ITEMS_SUCCESS
      });
    })
    .catch((e) => handleRequestError(dispatch, getState)(e, 'deleteSyllabusItems'));
};

const fetchEvolveUsers = (userId: string, useCache = false) => (dispatch, getState) => {
  if (useCache) {
    const evolveUser = cwSelectors.getEvolveUser(getState());
    if (evolveUser) {
      return Promise.resolve(evolveUser);
    }
  }
  dispatch({ type: coursewareActionType.REQUEST_START });
  return fetchCrosswalkUserService(userId, SystemType.EVOLVETYPE)
    .then((crosswalkUser) => {
      dispatch({
        payload: crosswalkUser,
        type: coursewareActionType.FETCH_EVOLVE_USER_SUCCESS
      });
      return crosswalkUser;
    })
    .catch((e) => handleRequestError(dispatch, getState)(e, 'fetchEvolveUsers'));
};

const fetchCrosswalkUsers = (userId: string) => (dispatch, getState) => {
  dispatch({ type: coursewareActionType.REQUEST_START });
  return fetchCrosswalkUserService(userId, SystemType.EVOLVETYPE)
    .then((crosswalkUser) => {
      return Promise.all(crosswalkUser.eolsUserIdsByUserNameAndType.map(eolsUserIdByUserNameAndType => {
        return fetchUserService(eolsUserIdByUserNameAndType.toString());
      }));
    })
    .then(users => dispatch({
      payload: users,
      type: coursewareActionType.FETCH_CROSSWALK_USERS_SUCCESS
    }))
    .catch((e) => handleRequestError(dispatch, getState)(e, 'fetchCrosswalkUsers'));
};

const fetchSkillStaticData = (skillVersionVtwId: string) => (dispatch, getState) => {
  dispatch({ type: coursewareActionType.REQUEST_START });
  return fetchSkillStaticDataService(skillVersionVtwId)
    .then((staticDataRes) => {
      dispatch({
        payload: staticDataRes,
        type: coursewareActionType.FETCH_SKILL_STATIC_DATA
      });
      return staticDataRes;
    })
    .catch((e) => handleRequestError(dispatch, getState)(e, 'fetchSkillStaticData'))
    .catch(() => []);
};

const postCourseCopy = (courseSectionId: string, startDate, timezone: string) => (dispatch, getState) => {
  dispatch({ type: coursewareActionType.REQUEST_START });
  return postCourseCopyService(courseSectionId, startDate, timezone)
    .then((data) => {
      dispatch({ type: coursewareActionType.REQUEST_SUCCESS });
      return data;
    })
    .catch((e) => handleRequestError(dispatch, getState)(e, 'postCourseCopy'));
};

const putAssignments = (assignments: AssignmentDto[]) => (dispatch, getState) => {
  dispatch({ type: coursewareActionType.REQUEST_START });
  const requests = assignments.map(assignment => eolsPutAssignmentService(assignment));
  return Promise.all(requests)
    .then(() => {
      const assignmentsDictionary = keyBy(assignments, 'id');
      dispatch({
        payload: assignmentsDictionary,
        type: coursewareActionType.PUT_ASSIGNMENTS_SUCCESS
      });
    })
    .catch((e) => handleRequestError(dispatch, getState)(e, 'putAssignments'));
};

const postCreateAssessmentFromAssignment = (newAssessment: Partial<AssessmentDto>) => (dispatch, getState) => {
  dispatch({ type: coursewareActionType.REQUEST_START });
  return postCreateAssessmentFromAssignmentService(newAssessment)
    .then((assessment) => {
      dispatch({
        payload: assessment,
        type: coursewareActionType.POST_CREATE_ASSESSMENT_FROM_ASSIGNMENT_SUCCESS
      });
      return assessment;
    })
    .catch((e) => handleRequestError(dispatch, getState)(e, 'postCreateAssessmentFromAssignment'));
};

const setIsBatchEditMode = (isBatchEditModeEnabled: boolean) => (dispatch) => {
  dispatch({
    payload: isBatchEditModeEnabled,
    type: coursewareActionType.SET_IS_BATCH_EDIT_MODE
  });
};

const setDragDropMode = (isDragDropMode: boolean) => (dispatch) => {
  dispatch({
    payload: isDragDropMode,
    type: coursewareActionType.SET_IS_DRAG_DROP_MODE
  });
};

const setCheckedSyllabusItemIds = (checkedSyllabusItemIds: string[]) => (dispatch) => {
  dispatch({
    payload: checkedSyllabusItemIds,
    type: coursewareActionType.SET_CHECKED_SYLLABUS_ITEMS
  });
};

const setSyllabusFolderInfo = (syllabusFolderInfo: SyllabusFolderLocationInfo) => (dispatch) => {
  dispatch({
    payload: syllabusFolderInfo,
    type: coursewareActionType.SET_SYLLABUS_FOLDER_INFO
  });
};

const updateCourseBuilderState = (newState: Partial<CourseBuilderStore>) => (dispatch) => {
  dispatch({
    payload: newState,
    type: coursewareActionType.UPDATE_COURSE_BUILDER_STATE
  });
};

const setAssessmentStart = (
  simulationAssignment: AssignmentDto,
  assessmentId: number,
  userId: string,
  startTime: Date
) => (dispatch, getState) => {
  dispatch({ type: coursewareActionType.REQUEST_START });
  return getInProgressAssessmentId(assessmentId, simulationAssignment.id, userId)(dispatch, getState)
    .then(inProgressAssessmentId => {
      if (inProgressAssessmentId) {
        const payload = {
          startTime,
          assessmentId: inProgressAssessmentId,
          assignment: simulationAssignment
        };
        dispatch({
          payload,
          type: coursewareActionType.SET_ASSESSMENT_START_TIME_MAP
        });
        return payload;
      }
      const newAssessment: Partial<AssessmentDto> = {
        assignmentId: simulationAssignment.id,
        userId: Number(userId)
      };
      return postCreateAssessmentFromAssignment(newAssessment)(dispatch, getState)
        .then((assessment) => {
          const payload = {
            startTime,
            assignment: simulationAssignment,
            assessmentId: assessment.id
          };
          dispatch({
            payload,
            type: coursewareActionType.SET_ASSESSMENT_START_TIME_MAP
          });
          return payload;
        })
        .catch((e) => handleRequestError(dispatch, getState)(e, 'setAssessmentStart'));
    });
};

const setRegisteredToken = (props: string) => (dispatch) => {
  dispatch({
    payload: props,
    type: coursewareActionType.SET_REGISTERED_TOKEN
  });
};

const setAppLinkCookies = (props: {
  token: string;
  linkId: string;
}) => (dispatch) => {
  dispatch({
    payload: props,
    type: coursewareActionType.SET_APP_LINK_COOKIES
  });
};

const updateCollapsedFolderIds = (props: string[], userId: string, courseSectionId: string) => (dispatch, getState) => {
  dispatch({
    payload: props,
    type: coursewareActionType.UPDATE_COLLAPSED_FOLDER_IDS
  });

  const eolsUserHistoryDTO = {
    eolsUser: {
      id: parseInt(userId, 10)
    },
    stateKey: CoursewareUserHistoryStateKey.COLLAPSED_FOLDERS,
    courseSectionId: parseInt(courseSectionId, 10),
    stateInfo: JSON.stringify(props)
  };

  return fetchUserHistoryByCourseByStateKeyService({
    userId: eolsUserHistoryDTO.eolsUser.id,
    stateKey: eolsUserHistoryDTO.stateKey,
    courseSectionId: eolsUserHistoryDTO.courseSectionId
  })
    .then((response: EolsUserHistoryResponseDto[]) => {
      return response.sort((a, b) => {
        return a.id - b.id;
      })
        .find((userHistory) => {
          return userHistory.stateKey === eolsUserHistoryDTO.stateKey;
        });
    })
    .catch((error) => {
      if (error.response && error.response.status === 404) {
        return {} as EolsUserHistoryResponseDto;
      }
      return Promise.reject(error);
    })
    .catch((e) => handleRequestError(dispatch, getState)(e, 'fetchUserHistoryByCourseByStateKeyService', false))
    .catch(() => {
      return {} as EolsUserHistoryResponseDto;
    })
    .then((userHistory) => {
      return postUserHistoryService({
        ...userHistory,
        ...eolsUserHistoryDTO
      });
    });
};

const setStoreProps = (props: Partial<CoursewareStore>) => (dispatch) => {
  dispatch({
    payload: props,
    type: coursewareActionType.SET_STORE_PROPS
  });
};

const fetchCourseCopyPreview = (courseSectionId: string, timezone: string, startDate: string) => (dispatch, getState): Promise<CourseCopyPreviewDto> => {
  dispatch({ type: coursewareActionType.REQUEST_START });
  return fetchCourseCopyPreviewService(courseSectionId, timezone, startDate)
    .then((courseCopyPreviewDto) => {
      const payload: Partial<CourseBuilderStore> = {
        courseCopyPreviewDto
      };
      dispatch({
        payload,
        type: coursewareActionType.FETCH_COURSE_COPY_PREVIEW_SUCCESS
      });
      return Promise.resolve(courseCopyPreviewDto);
    })
    .catch((e) => handleRequestError(dispatch, getState)(e, 'fetchCourseCopyPreview'));
};

const setBatchEditSelectedSyllabusItems = (props: SyllabusItemDto[]) => (dispatch) => {
  dispatch({
    payload: props,
    type: coursewareActionType.SET_BATCH_EDIT_SELECTED_SYLLABUS_ITEMS
  });
};

const setBatchEditUpdatedSyllabusItems = (props: SyllabusItemDto[]) => (dispatch) => {
  dispatch({
    payload: props,
    type: coursewareActionType.SET_BATCH_EDIT_UPDATED_SYLLABUS_ITEMS
  });
};

const setEnableDeepLink = (enableDeepLink: boolean) => (dispatch) => {
  dispatch({
    payload: enableDeepLink,
    type: coursewareActionType.SET_ENABLE_DEEP_LINK
  });
};

const fetchUserEngagementReport = (courseSectionId: string) => (dispatch, getState) => {
  dispatch({ type: coursewareActionType.REQUEST_START });

  return fetchUserEngagementReportService(courseSectionId)
    .then((response: EolsUserEngagementDto[]) => {
      dispatch({
        payload: response,
        type: coursewareActionType.FETCH_USER_ENGAGEMENT_REPORT_SUCCESS
      });
      return response;
    })
    .catch((e) => handleRequestError(dispatch, getState)(e, 'fetchUserEngagementReport'));
};

const setHasRunAuthessHealthCheck = (hasRunAuthessHealthCheck: boolean) => (dispatch) => {
  dispatch({
    payload: hasRunAuthessHealthCheck,
    type: coursewareActionType.SET_HAS_RUN_AUTHESS_HEALTH_CHECK
  });
};

const fetchAuthessHealthCheck = (courseSectionId: string) => (dispatch, getState) => {
  return fetchAuthessHealthCheckService(courseSectionId)
    .then((response) => {
      return response;
    })
    .catch((e) => handleRequestError(dispatch, getState)(e, 'fetchAuthessHealthCheck', false));
};

const putMigrateSyllabusItemsAction = (courseSectionId: string) => (dispatch, getState) => {
  dispatch({ type: coursewareActionType.REQUEST_START });
  return putMigrateSyllabusItems(courseSectionId)
    .then((response) => {
      dispatch({ type: coursewareActionType.REQUEST_SUCCESS });
      return response;
    })
    .catch((e) => handleRequestError(dispatch, getState)(e, 'putMigrateSyllabusItemsAction'));
};

const fetchEvolveResourceAction = (vtwId: string) => (dispatch, getState) => {
  dispatch({ type: coursewareActionType.REQUEST_START });
  return fetchEvolveResource(vtwId)
    .then((response) => {
      dispatch({ type: coursewareActionType.REQUEST_SUCCESS });
      return response;
    })
    .catch((e) => handleRequestError(dispatch, getState)(e, 'fetchEvolveResourceAction'))
    .catch(() => null);
};

const fetchHashLinkAction = (hashLink: string) => (dispatch, getState) => {
  dispatch({ type: coursewareActionType.REQUEST_START });
  return fetchHashLink(hashLink)
    .then((response) => {
      dispatch({ type: coursewareActionType.REQUEST_SUCCESS });
      return response;
    })
    .catch((e) => handleRequestError(dispatch, getState)(e, 'fetchHashLinkAction'));
};

const fetchResourceInfoAction = (vtwId: string) => (dispatch, getState) => {
  dispatch({ type: coursewareActionType.REQUEST_START });
  return fetchResourceInfo(vtwId)
    .then((response) => {
      dispatch({ type: coursewareActionType.REQUEST_SUCCESS });
      return response;
    })
    .catch((e) => handleRequestError(dispatch, getState)(e, 'fetchResourceInfoAction'))
    .catch(() => {
      return null;
    });
};

const fetchDeepLinkDataAction = (deepLinkGuid: string, courseSectionId: string) => (dispatch, getState) => {
  dispatch({ type: coursewareActionType.REQUEST_START });
  return fetchDeepLinkData(deepLinkGuid, courseSectionId)
    .then((response) => {
      dispatch({ type: coursewareActionType.REQUEST_SUCCESS });
      return response;
    })
    .catch((e) => handleRequestError(dispatch, getState)(e, 'fetchDeepLinkDataAction'));
};

const postDeepLinkAction = (data: DeepLinkPostDto) => (dispatch, getState) => {
  dispatch({ type: coursewareActionType.REQUEST_START });
  return postDeepLink(data)
    .then((response) => {
      dispatch({ type: coursewareActionType.REQUEST_SUCCESS });
      return response;
    })
    .catch((e) => handleRequestError(dispatch, getState)(e, 'postDeepLinkAction'));
};

const fetchAssignmentAction = (assignmentId: string) => (dispatch, getState) => {
  dispatch({ type: coursewareActionType.REQUEST_START });
  return fetchAssignment(assignmentId)
    .then((assignment) => {
      dispatch({
        payload: assignment,
        type: coursewareActionType.FETCH_ASSIGNMENT_SUCCESS
      });
      return assignment;
    })
    .catch((e) => handleRequestError(dispatch, getState)(e, 'fetchAssignmentAction'));
};

const fetchRelatedHesiCoursesAction = (userId: string) => (dispatch, getState) => {
  dispatch({ type: coursewareActionType.REQUEST_START });
  return fetchRelatedHesiCourses(userId)
    .then((response) => {
      dispatch({ type: coursewareActionType.REQUEST_SUCCESS });
      return response;
    })
    .catch((e) => handleRequestError(dispatch, getState)(e, 'fetchRelatedHesiCoursesAction'));
};

const getShadowHealthRelatedCoursesAction = (userId: string) => (dispatch, getState) => {
  dispatch({ type: coursewareActionType.REQUEST_START });
  return getShadowHealthRelatedCourses(userId)
    .then((response) => {
      dispatch({ type: coursewareActionType.REQUEST_SUCCESS });
      return response;
    })
    .catch((e) => handleRequestError(dispatch, getState)(e, 'getShadowHealthRelatedCoursesAction'));
};

const fetchNewTokenForCourseSwitcherAction = (body: AccessTokenRequestDto) => (dispatch, getState) => {
  dispatch({ type: coursewareActionType.REQUEST_START });
  return fetchNewTokenForCourseSwitcher(body)
    .then((response) => {
      dispatch({ type: coursewareActionType.REQUEST_SUCCESS });
      return response;
    })
    .catch((e) => handleRequestError(dispatch, getState)(e, 'fetchNewTokenForCourseSwitcherAction'));
};

const fetchUserCourseSectionsAction = (userId: string, active = true, entitled?: boolean) => (dispatch, getState) => {
  dispatch({ type: coursewareActionType.REQUEST_START });
  return fetchUserCourseSections(userId, active, entitled)
    .then((response) => {
      dispatch({ type: coursewareActionType.REQUEST_SUCCESS });
      return response;
    })
    .catch((e) => handleRequestError(dispatch, getState)(e, 'fetchUserCourseSectionsAction'));
};

const goToShadowAction = (course: ShadowHealthCourseDto) => (dispatch, getState) => {
  dispatch({ type: coursewareActionType.REQUEST_START });
  return goToShadow(course)
    .then((response) => {
      dispatch({ type: coursewareActionType.REQUEST_SUCCESS });
      return response;
    })
    .catch((e) => handleRequestError(dispatch, getState)(e, 'goToShadowAction'));
};

const fetchLearningObjectLessonAndModuleAction = (vtwId: string) => (dispatch, getState) => {
  dispatch({ type: coursewareActionType.REQUEST_START });
  return fetchLearningObjectLessonAndModule(vtwId)
    .then((response) => {
      dispatch({ type: coursewareActionType.REQUEST_SUCCESS });
      return response;
    })
    .catch((e) => handleRequestError(dispatch, getState)(e, 'fetchLearningObjectLessonAndModuleAction'))
    .catch(() => {
      return null;
    });
};

const fetchAnalyticsContextAction = (analyticsRequestDto: LearningAnalyticsRequestDto) => (dispatch, getState) => {
  dispatch({ type: coursewareActionType.REQUEST_START });
  return fetchAnalyticsContext(analyticsRequestDto)
    .then((response) => {
      dispatch({ type: coursewareActionType.REQUEST_SUCCESS });
      return response;
    })
    .catch((e) => handleRequestError(dispatch, getState)(e, 'fetchAnalyticsContextAction'))
    .catch(() => null);
};

const fetchLessonTopicsAction = (lessonId: string) => (dispatch, getState) => {
  dispatch({ type: coursewareActionType.REQUEST_START });
  return fetchLessonTopics(lessonId)
    .then((response) => {
      dispatch({ type: coursewareActionType.REQUEST_SUCCESS });
      return response;
    })
    .catch((e) => handleRequestError(dispatch, getState)(e, 'fetchLessonTopicsAction'));
};

const fetchAssessmentSubmissionsAction = (assignmentId: string) => (dispatch, getState) => {
  dispatch({ type: coursewareActionType.REQUEST_START });
  return fetchAssessmentSubmissionsService(assignmentId)
    .then((response) => {
      dispatch({ type: coursewareActionType.REQUEST_SUCCESS });
      return response;
    })
    .catch((e) => handleRequestError(dispatch, getState)(e, 'fetchAssessmentSubmissionsAction'));
};

const fetchSyllabusItemAction = (id: string) => (dispatch, getState) => {
  dispatch({ type: coursewareActionType.REQUEST_START });
  return fetchSyllabusItem(id)
    .then((response) => {
      dispatch({ type: coursewareActionType.REQUEST_SUCCESS });
      return response;
    })
    .catch((e) => handleRequestError(dispatch, getState)(e, 'fetchSyllabusItemAction'));
};

const fetchAssignmentResultsAction = (assignmentId: number) => (dispatch, getState) => {
  dispatch({ type: coursewareActionType.REQUEST_START });
  return fetchAssignmentResults(assignmentId)
    .then((response) => {
      dispatch({ type: coursewareActionType.REQUEST_SUCCESS });
      return response;
    })
    .catch((e) => handleRequestError(dispatch, getState)(e, 'fetchAssignmentResultsAction'));
};

const fetchSkillPerformanceAction = (assignmentId: number) => (dispatch, getState) => {
  dispatch({ type: coursewareActionType.REQUEST_START });
  return fetchSkillPerformance(assignmentId)
    .then((response) => {
      dispatch({ type: coursewareActionType.REQUEST_SUCCESS });
      return response;
    })
    .catch((e) => handleRequestError(dispatch, getState)(e, 'fetchSkillPerformanceAction'));
};

const fetchOsmosisTokenAction = () => (dispatch, getState) => {

  const token = cwSelectors.getOsmosisTokenDto(getState());
  if (!shouldGetNewOsmosisToken(token)) {
    return Promise.resolve(token);
  }

  dispatch({ type: coursewareActionType.REQUEST_START });
  return fetchOsmosisToken()
    .then((response) => {
      dispatch({
        type: coursewareActionType.FETCH_OSMOSIS_TOKEN_SUCCESS,
        payload: response
      });
      return response;
    })
    .catch((e) => handleRequestError(dispatch, getState)(e, 'fetchOsmosisTokenAction'));
};

const fetchMigratedEntitlementsAction = (courseSectionId: string) => (dispatch, getState) => {
  const migratedEntitlements = cwSelectors.getMigratedEntitlements(getState());
  if (migratedEntitlements) {
    return Promise.resolve(migratedEntitlements);
  }

  dispatch({ type: coursewareActionType.REQUEST_START });
  return fetchMigratedEntitlements(courseSectionId)
    .then((response) => {
      dispatch({
        type: coursewareActionType.FETCH_MIGRATED_ENTITLEMENTS_SUCCESS,
        payload: response
      });
      return response;
    })
    .catch((e) => handleRequestError(dispatch, getState)(e, 'fetchMigratedEntitlementsAction'));
};

const openSupportTicket = (supportTicketDto: SupportTicketPostBodyDto) => (dispatch, getState) => {
  dispatch({ type: coursewareActionType.REQUEST_START });
  return postGetSupportTicketUrl(supportTicketDto)
    .then(rightNowLink => {
      dispatch({ type: coursewareActionType.REQUEST_SUCCESS });
      return window.open(rightNowLink, '_blank');
    })
    .catch((e) => handleRequestError(dispatch, getState)(e, 'openSupportTicket'));
};

const goToEvolve = () => (dispatch, getState) => {
  dispatch({ type: coursewareActionType.REQUEST_START });
  return getEvolveToken()
    .then((response) => {
      dispatch({ type: coursewareActionType.REQUEST_SUCCESS });
      const { myEvolveUrl } = ServerConstants[ELSCommonConfig.appProfile];
      return window.open(`${myEvolveUrl}?token=${response.token.token}`, '_blank');
    })
    .catch((e) => handleRequestError(dispatch, getState)(e, 'goToEvolve'));
};

const saveAssignment = (assignment: Partial<AssignmentDto>, isGradepointIndependentGoals = false) => (dispatch, getState): Promise<AssignmentDto> => {
  dispatch({ type: coursewareActionType.REQUEST_START });
  let request = assignment.id ? eolsPutAssignment : eolsPostAssignment; // eols services will be default
  if (SyllabusItemTypeConfigMap[ActiveSyllabusItemTypeDto.ADAPTIVE_QUIZ].assignmentTypes.includes(assignment.assignmentType)) {
    request = assignment.id ? eaqPutAssignment : eaqPostAssignment;
  }
  return request(assignment, isGradepointIndependentGoals)
    .then((response) => {
      dispatch({
        type: coursewareActionType.SAVE_ASSIGNMENT,
        payload: response
      });
      return response;
    })
    .catch((e) => handleRequestError(dispatch, getState)(e, 'saveAssignment'));
};

const setInteractiveReviewCatalogItem = (catalogItem: RecContentItemDto) => (dispatch) => {
  dispatch({ payload: catalogItem, type: coursewareActionType.SET_INTERACTIVE_REVIEW_CATALOG_ITEM });
};

const setImportSelection = (assignment: Partial<AssignmentDto>) => (dispatch) => {
  dispatch({
    payload: assignment,
    type: coursewareActionType.SET_IMPORT_SELECTION
  });
};

export const cwActions = {
  deleteSyllabusItems,
  deleteUserHistoryByUserId: deleteUserHistoryWithUserId,
  fetchAppLinkData,
  fetchAssessmentSubmissions,
  fetchRecentAssessmentOfAssignment,
  fetchAssignments,
  fetchCatalog,
  fetchCourseSection,
  fetchCrosswalkUsers,
  fetchEvolveUsers,
  fetchPrimaryTaxonomies,
  fetchCourseCopyPreview,
  fetchSyllabusItems,
  fetchUserCourseOwnerRecords,
  fetchUsers,
  fetchEaqUsersAction,
  fetchIsbnsAction,
  resetMasteryAction,
  fetchUserHistoryByCourseByStateKey,
  navigateToApp,
  openVitalSource,
  postCourseCopy,
  postSyllabusAssignment,
  postSyllabusItem,
  postUserHistory,
  putSyllabusAssignment,
  putSyllabusItems,
  putAssignments,
  resetState,
  resetStateOnLaunch,
  restoreState,
  setAssessmentStart,
  setCheckedSyllabusItemIds,
  setSyllabusFolderInfo,
  setDragDropMode,
  setIsBatchEditMode,
  setIsbns,
  setIsCourseOwner,
  setMessages,
  setStoreProps,
  setUser,
  setRegisteredToken,
  updateCourseBuilderState,
  fetchAllAppFeatureFlags,
  fetchSkillStaticData,
  fetchSkillSubmissionRecords,
  setAppLinkCookies,
  returnAppLink,
  updateCollapsedFolderIds,
  setBatchEditSelectedSyllabusItems,
  setBatchEditUpdatedSyllabusItems,
  trackAction,
  fetchUserEngagementReport,
  trackAdobeAction,
  setEnableDeepLink,
  setHasRunAuthessHealthCheck,
  fetchAuthessHealthCheck,
  putMigrateSyllabusItemsAction,
  fetchEvolveResourceAction,
  fetchHashLinkAction,
  fetchResourceInfoAction,
  fetchDeepLinkDataAction,
  postDeepLinkAction,
  fetchAssignmentAction,
  fetchRelatedHesiCoursesAction,
  getShadowHealthRelatedCoursesAction,
  fetchNewTokenForCourseSwitcherAction,
  fetchUserCourseSectionsAction,
  goToShadowAction,
  fetchLearningObjectLessonAndModuleAction,
  fetchAnalyticsContextAction,
  fetchLessonTopicsAction,
  fetchAssessmentSubmissionsAction,
  fetchSyllabusItemAction,
  fetchAssignmentResultsAction,
  getExternalAppRedirectUrlAction,
  fetchSkillPerformanceAction,
  postCreateAssessmentFromAssignment,
  fetchOsmosisTokenAction,
  fetchAssessmentsByAssignmentIdAndUserIdAction,
  fetchAllAssessmentSubmissionsAction,
  fetchMigratedEntitlementsAction,
  openSupportTicket,
  goToEvolve,
  saveAssignment,
  setInteractiveReviewCatalogItem,
  setImportSelection
};
