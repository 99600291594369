import {
  eolsBaseApi,
  withApiErrorLoggingOnly
} from '../../utilities/api.utilities';
import {
  AuthessAssignmentDto,
  AuthessAssignmentsMapDto,
  AuthessHealthResponse,
  BookTaxonomyKindDto,
  CourseISBNDto,
  EaqAssessmentDetailDto,
  EaqQuestionDetailDto,
  EaqRecommenderQuestionsDto,
  EaqTaxonomyDto,
  EaqTopicDto,
  EaqUserDto,
  MasteryResetDto
} from './eaq-app-facade-service.dtos';
import { AssignmentDto } from '../eols-assessment-service/eols-assessment-service.dtos';
import { addSearchParams } from '../../utilities/app.utilities';

export const assignmentUrl = '/eaq/assignment';

export const fetchAuthessHealthCheck = (courseSectionId: string): Promise<AuthessHealthResponse> => {
  return withApiErrorLoggingOnly(() => {
    return eolsBaseApi.get<AuthessHealthResponse>(
      `/eaq/authess/courseSection/${courseSectionId}/healthcheck`
    );
  });
};

export const eaqPutAssignment = (assignment: Partial<AssignmentDto>): Promise<AssignmentDto> => {
  return withApiErrorLoggingOnly(() => {
    return eolsBaseApi.put(assignmentUrl, { data: assignment });
  });
};

export const eaqPostAssignment = (assignment: Partial<AssignmentDto>): Promise<AssignmentDto> => {
  return withApiErrorLoggingOnly(() => {
    return eolsBaseApi.post(assignmentUrl, { data: assignment });
  });
};

export const fetchEaqUsers = (courseSectionIds: string[]): Promise<EaqUserDto[]> => {
  return withApiErrorLoggingOnly(() => {
    return eolsBaseApi.get(`/eaq/courseSections/${courseSectionIds}/students`);
  });
};

export const fetchIsbns = (courseSectionId: string, userId): Promise<CourseISBNDto[]> => {
  return withApiErrorLoggingOnly(() => {
    return eolsBaseApi.get(`/eaq/eolsUser/${userId}/courseSection/${courseSectionId}/questionSets`);
  });
};

export const resetMastery = (isbn: string, userId: number): Promise<MasteryResetDto> => {
  return withApiErrorLoggingOnly(() => {

    const queryParams = {
      userId,
      isbn
    };
    return eolsBaseApi.post(addSearchParams('/eaq/userPerformance/reset/job', queryParams));
  });
};

export const eaqTopicOrganization = (eaqISBN: string, topicId: string, courseId: number): Promise<EaqTopicDto> => {
  return withApiErrorLoggingOnly(() => {
    return eolsBaseApi.get(`/eaq/assessment/mastery/${eaqISBN}/topic/${topicId}/${courseId}`);
  });
};

export const eaqTaxonomy = (isbn: string, bookTaxonomyKind = BookTaxonomyKindDto.ALL): Promise<EaqTaxonomyDto[]> => {
  return withApiErrorLoggingOnly(() => {
    return eolsBaseApi.get(`/eaq/recommender/isbn/${isbn}/taxonomies?bookTaxonomyKind=${bookTaxonomyKind}`);
  });
};

export const fetchHasAuthessAssignments = (courseSectionId: string): Promise<AuthessAssignmentsMapDto> => {
  return withApiErrorLoggingOnly(() => {
    return eolsBaseApi.get(addSearchParams('/eaq/authess/assignments/hasAvailable', { courseSectionId }));
  });
};

export const fetchAuthessAssignments = (isbn: string): Promise<AuthessAssignmentDto[]> => {
  return withApiErrorLoggingOnly(() => {
    return eolsBaseApi.get(addSearchParams('/eaq/authess/assignments', { isbn }));
  });
};
export const eaqAppLinkOut = (props: {
  targetApp: string;
  action: string;
  parameters: Record<string, string | boolean | number>;
  returnParameters: Record<string, string | boolean | number>;
  returnUrl: string;
}): Promise<{
  redirectUrl: string;
}> => {

  const {
    targetApp,
    returnUrl,
    action,
    parameters,
    returnParameters
  } = props;

  return withApiErrorLoggingOnly(() => {
    return eolsBaseApi.post(`/eaq/link/${targetApp}`, {
      data: {
        action,
        parameters,
        returnUrl: returnUrl || `https://${window.location.hostname}/#/eaq-redirect`,
        returnParameters
      }
    });
  });
};

export const eaqRecommenderQuestionsCount = (query: Partial<EaqRecommenderQuestionsDto>): Promise<number> => {
  return withApiErrorLoggingOnly(() => {

    const postBody: Partial<EaqRecommenderQuestionsDto> = {
      ...query,
      size: 20,
      questionCount: true
    };

    if (!postBody.keyword) {
      delete postBody.keyword;
    }

    return eolsBaseApi.post('/eaq/recommender/questions/search', { data: postBody });
  });
};

export const eaqRecommenderQuestions = (query: Partial<EaqRecommenderQuestionsDto>): Promise<EaqQuestionDetailDto[]> => {
  return withApiErrorLoggingOnly(() => {

    const postBody: Partial<EaqRecommenderQuestionsDto> = {
      size: 100,
      ...query,
      questionCount: false
    };

    if (!postBody.keyword) {
      delete postBody.keyword;
    }

    return eolsBaseApi.post('/eaq/recommender/questions/search', { data: postBody });
  });
};

export const eaqHesiFocusChapters = (collectionIsbn: string): Promise<EaqTaxonomyDto> => {
  return withApiErrorLoggingOnly(() => {
    return eolsBaseApi.get(addSearchParams('/eaq/recommender/taxonomies/hesiFocusChapters', { collectionIsbn }));
  });
};

export const fetchEaqAssignment = (assignmentId: string): Promise<AssignmentDto> => {
  return withApiErrorLoggingOnly(() => {
    return eolsBaseApi.get(`/eaq/assignment/${assignmentId}`);
  });
};

export const fetchEaqAssessmentDetail = (assignmentId: string): Promise<EaqAssessmentDetailDto[]> => {
  return withApiErrorLoggingOnly(() => {
    return eolsBaseApi.get(`/eaq/assignmentDetail/${assignmentId}`);
  });
};

export const getEaqAssignmentSummariesForCourse = (courseSectionId: string): Promise<AssignmentDto[]> => {
  return withApiErrorLoggingOnly(() => {
    return eolsBaseApi.get(`${assignmentUrl}?courseSectionId=${courseSectionId}`);
  });
};
